import React, { useEffect, useState } from 'react';
import Icon from '../../design-system/Icon';
import { Link } from 'react-router-dom';
import { IToolAnchorMenu, sidebarAppAnchors } from '../consts';
import { Anchor, Grid, ToolAnchor } from '../../design-system';
import Collapse from '../collapse';
import { useTranslation } from 'react-i18next';
import { changeQuestionById } from '../../reducers/sidebar';
import useSidebar from './hooks/useSidebar';
import { useDispatch } from 'react-redux';
import { User } from '@supabase/supabase-js';
import { supabase } from '../../supabaseClient';

interface ISidebar {
  variant: 'APP' | 'TOOL';
}

const Sidebar = ({ variant }: ISidebar) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sidebar } = useSidebar();
  const [user, setUser] = useState<User | null>({} as User);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  useEffect(() => {
    const handleSession = async () => {
      const sessionData = await supabase.auth.getSession();
      setUser(sessionData?.data.session?.user ?? null);
    };

    handleSession();
  }, []);

  const goToQuestions = ({
    anchorId,
    questionId,
  }: {
    anchorId: number | string;
    questionId: number | string;
  }) => {
    setOpenMenu(false);
    dispatch(
      changeQuestionById({
        category_id: anchorId,
        question_id: questionId,
      })
    );
  };

  return (
    <div className="flex flex-col px-spacing-5 msm:pt-spacing-10 md:pt-spacing-24 pb-spacing-4 w-spacing-270 msm:h-auto">
      <div className="flex items-center justify-between ml-spacing-16 mb-spacing-26">
        <div className="md:hidden">
          <button onClick={() => setOpenMenu(!openMenu)}>
            <Icon name="BurgerMenu" />
          </button>
        </div>
        <Link to={'/'}>
          <Icon name="Logo" width={90} height={24} />
        </Link>
      </div>
      <div
        className={`msm:fixed msm:w-3/4 msm:z-20 msm:bg-white msm:h-screen msm:top-spacing-48 msm:pt-spacing-20 msm:transition-all ${
          openMenu ? 'msm:left-spacing-0' : 'msm:-left-spacing-610'
        }`}
      >
        {variant === 'APP' ? (
          <>
            <div className="flex-1">
              {sidebarAppAnchors.map((anchor) => (
                <Anchor
                  {...anchor}
                  key={anchor.label}
                  className="mb-spacing-8"
                />
              ))}
            </div>
            <>
              <Anchor
                label={'Ludwig Frank'}
                description={user?.email}
                avatar={'https://avatars.githubusercontent.com/u/98967193?v=4'}
                path="/profile"
              />
              <Grid
                noPadding
                fluid
                onClick={() => supabase.auth.signOut().catch(console.error)}
                className="px-spacing-18 my-spacing-20 cursor-pointer"
              >
                <Icon name="Leave" />
              </Grid>
            </>
          </>
        ) : (
          <>
            <div className="flex-1">
              {sidebar.map((anchor: IToolAnchorMenu, index) => {
                const anchorProps = {
                  ...anchor,
                  label: t(anchor.label),
                };

                return (
                  <Collapse
                    header={<ToolAnchor {...anchorProps} />}
                    disabled={anchor.disabled}
                    className={
                      !anchor.done && !anchor.disabled ? 'py-spacing-16' : null
                    }
                    active={anchor.opened}
                    key={anchor.label + index}
                  >
                    <div className="ml-spacing-16">
                      {anchor.questions?.map((question) => {
                        const questionProps = {
                          ...question,
                          label: t(question.label),
                          action: () =>
                            goToQuestions({
                              anchorId: anchor.id,
                              questionId: question.id,
                            }),
                        };

                        return (
                          <ToolAnchor key={question.label} {...questionProps} />
                        );
                      })}
                    </div>
                  </Collapse>
                );
              })}
            </div>
          </>
        )}
      </div>

      <div
        className={`fixed bg-black w-screen h-screen top-spacing-48 transition-all left-0 ${
          openMenu ? 'opacity-70 z-10' : 'opacity-0 -z-10'
        }`}
        onClick={() => setOpenMenu(!openMenu)}
      ></div>
    </div>
  );
};

export default Sidebar;
