import { useEffect, useState, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes as ListRoutes,
} from 'react-router-dom';
import { User } from '@supabase/supabase-js';
import { supabase } from './supabaseClient';
import Layout from './components/layout/Layout';
import Protected from './components/ProtectedRoute';
import LoadingComponents from './components/loadingComponents/loadingComponents';

const Home = lazy(() => import('./screens/AppScreens/Home'));
const Login = lazy(() => import('./screens/AppScreens/Authentication/Login'));
const Register = lazy(
  () => import('./screens/AppScreens/Authentication/Register')
);
const ForgotPassword = lazy(
  () => import('./screens/AppScreens/Authentication/ForgotPassword')
);
const ResetPassword = lazy(
  () => import('./screens/AppScreens/Authentication/ResetPassword')
);
const ToolQuestions = lazy(
  () => import('./screens/ToolScreens/screens/Questions')
);

const Routes = () => {
  const [user, setUser] = useState<User | null>({} as User);

  useEffect(() => {
    const handleSession = async () => {
      const sessionData = await supabase.auth.getSession();
      setUser(sessionData?.data.session?.user ?? null);

      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (_, session) => {
          const currentUser = session?.user;
          setUser(currentUser ?? null);
          if (currentUser && window.location.pathname.search(/login/gi) > -1) {
            window.location.href = '/';
          }
        }
      );

      return () => {
        authListener.subscription.unsubscribe();
      };
    };

    handleSession();
  }, []);

  return (
    <Router>
      <ListRoutes>
        <Route
          path="/register"
          element={
            <LoadingComponents variant="FULL">
              <Register />
            </LoadingComponents>
          }
        />
        <Route
          path="/forgot_password"
          element={
            <LoadingComponents variant="FULL">
              <ForgotPassword />
            </LoadingComponents>
          }
        />
        <Route
          path="/reset_password"
          element={
            <LoadingComponents variant="FULL">
              <ResetPassword />
            </LoadingComponents>
          }
        />
        <Route
          path="/trt"
          element={
            <LoadingComponents variant="FULL">
              <ToolQuestions />
            </LoadingComponents>
          }
        />
        <Route
          path="/login"
          element={
            <LoadingComponents variant="FULL">
              <Login />
            </LoadingComponents>
          }
        />
        <Route
          element={
            <Protected isLoggedIn={user !== null}>
              <Layout />
            </Protected>
          }
        >
          <Route
            path="/"
            element={
              <LoadingComponents variant="APP">
                <Home />
              </LoadingComponents>
            }
          />
        </Route>
      </ListRoutes>
    </Router>
  );
};

export default Routes;
