import { FlagsList } from '../../design-system/Icon/flags/enum';
import { IconsList } from '../../design-system/Icon/icons/enum';

export interface IAnswer {
  id: string;
  label: string;
  icon?: FlagsList | IconsList;
  aditionalQuestions?: string[];
  description?: string;
  key?: string;
  value?: string | boolean;
  errorTexts?: any;
}

export enum ANSWER_VARIATION {
  WithLabelBorder = 'WithLabelBorder',
  WithIcon = 'WithIcon',
  Horizontal = 'Horizontal',
}
export interface IAnswerType {
  type: 'Pill' | 'Radio' | 'Input';
  variation: ANSWER_VARIATION;
}

export interface IQuestionLightboxText {
  title: string;
  text: string;
}
export interface IQuestionLightbox {
  title: string;
  text: IQuestionLightboxText[];
}
export interface IQuestionFlow {
  id: string;
  label: string;
  title: string;
  irregularTitle?: string;
  type: string;
  answerLimit: number;
  required: boolean;
  answerType: IAnswerType;
  answers: IAnswer[];
  component: string;
  description?: string;
  hook?: string;
  variables?: object;
  key?: string;
  toCountry?: boolean;
  toMetadata?: boolean;
  jumpTo?: string;
  isLastQuestion?: boolean;
  lightbox?: IQuestionLightbox;
}

const defaultQuestions: {
  [key: string]: IQuestionFlow;
} = {
  fiscal_year: {
    id: 'fiscal_year',
    label: 'questions.fiscal_year.label',
    title: 'questions.fiscal_year.title',
    description: 'questions.fiscal_year.description',
    type: 'multiple_choice',
    lightbox: {
      title: 'TBD',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.Horizontal,
    },
    answers: [
      {
        id: 'question_0_answer_2',
        label: 'questions.fiscal_year.answers.0.label',
        description: 'questions.fiscal_year.answers.0.description',
        key: 'yearTaxResidence',
        value: '2021',
      },
      {
        id: 'question_0_answer_3',
        label: 'questions.fiscal_year.answers.1.label',
        description: 'questions.fiscal_year.answers.1.description',
        key: 'yearTaxResidence',
        value: '2020',
      },
      {
        id: 'question_0_answer_4',
        label: 'questions.fiscal_year.answers.2.label',
        description: 'questions.fiscal_year.answers.2.description',
        key: 'yearTaxResidence',
        value: '2019',
      },
      {
        id: 'question_0_answer_5',
        label: 'questions.fiscal_year.answers.3.label',
        description: 'questions.fiscal_year.answers.3.description',
        key: 'yearTaxResidence',
        value: '2018',
      },
      {
        id: 'question_0_answer_6',
        label: 'questions.fiscal_year.answers.4.label',
        description: 'questions.fiscal_year.answers.4.description',
        key: 'yearTaxResidence',
        value: '2017',
      },
    ],
    component: 'Question',
    toMetadata: true,
    hook: 'default/FiscalYear/useFiscalYear',
  },
  countries: {
    id: 'countries',
    label: 'questions.countries.label',
    title: 'questions.countries.title',
    description: 'questions.countries.description',
    type: 'multiple_choice',
    key: 'initialInputCountries',
    lightbox: {
      title: 'How to count my days of stay?',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    answerLimit: 4,
    required: true,
    answerType: {
      type: 'Pill',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [],
    component: 'default/Countries',
    hook: 'default/Countries/useCountries',
    variables: { year: '2022' },
    toMetadata: true,
  },
  passport: {
    id: 'passport',
    label: 'questions.passport.label',
    title: 'questions.passport.title',
    description: 'questions.passport.description',
    type: 'multiple_choice',
    lightbox: {
      title: 'Why does this matter?',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    answerLimit: 4,
    required: true,
    answerType: {
      type: 'Pill',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [],
    component: 'Question',
    key: 'hasNationality',
    toCountry: true,
  },
  immigration: {
    id: 'immigration',
    label: 'questions.immigration.label',
    title: 'questions.immigration.title',
    description: 'questions.immigration.description',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [
      {
        id: 'question_2_answer_1',
        label: 'questions.immigration.answers.0.label',
        aditionalQuestions: ['question_2_1', 'question_2_2', 'question_2_3'],
        key: 'hasImmigrated',
        value: true,
      },
      {
        id: 'question_2_answer_2',
        label: 'questions.immigration.answers.1.label',
        key: 'hasImmigrated',
        value: false,
      },
    ],
    lightbox: {
      title: 'questions.immigration.lightbox.title',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    component: 'Question',
    toMetadata: true,
  },
  question_2_1: {
    id: 'question_2_1',
    label: 'questions.immigration.aditionalQuestions.0.label',
    title: 'questions.immigration.aditionalQuestions.0.title',
    description: 'questions.immigration.description',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Input',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [
      {
        id: 'question_2_1_answer_1',
        label: 'questions.immigration.aditionalQuestions.0.answers.0.label',
        key: 'dateYouMoved',
        value: '',
        errorTexts: {
          invalid: 'questions.immigration.aditionalQuestions.0.answers.0.error',
          notBetween:
            'questions.immigration.aditionalQuestions.0.answers.0.notBetweenError',
        },
      },
    ],
    component: 'default/QuestionInputDate',
    key: 'dateYouMoved',
    toMetadata: true,
  },
  question_2_2: {
    id: 'question_2_2',
    label: 'questions.immigration.aditionalQuestions.1.label',
    title: 'questions.immigration.aditionalQuestions.1.title',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Pill',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [],
    component: 'Question',
    key: 'countryMoveFrom',
    toMetadata: true,
  },
  question_2_3: {
    id: 'question_2_3',
    label: 'questions.immigration.aditionalQuestions.2.label',
    title: 'questions.immigration.aditionalQuestions.2.title',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Pill',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [],
    component: 'Question',
    key: 'countryMoveTo',
    toMetadata: true,
  },
  previous_tax_return: {
    id: 'previous_tax_return',
    label: 'questions.previous_tax_return.label',
    title: 'questions.previous_tax_return.title',
    lightbox: {
      title: 'questions.previous_tax_return.lightbox.title',
      text: [],
    },
    description: 'questions.previous_tax_return.description',
    type: 'multiple_choice',
    key: 'hasFiledLastYear',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Pill',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [
      {
        id: 'question_3_answer_1',
        label: 'questions.previous_tax_return.answers.0.label',
        value: false,
      },
    ],
    component: 'default/PreviousTaxReturn',
    hook: 'default/PreviousTaxReturn/usePreviousTaxReturn',
    toCountry: true,
  },
  irregular_fy: {
    id: 'irregular_fy',
    label: 'questions.irregular_fy.label',
    title: 'questions.irregular_fy.title',
    type: 'multiple_choice',
    answerLimit: 3,
    required: true,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [],
    component: 'default/IrregularFY',
  },
  historic_ties: {
    id: 'historic_ties',
    label: 'questions.historic_ties.label',
    title: 'questions.historic_ties.title',
    description: 'questions.historic_ties.description',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Pill',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    lightbox: {
      title: 'Why does this matter?',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    answers: [],
    component: 'Question',
    key: 'hasHistoricalTies',
    toCountry: true,
  },
  permanent_home: {
    id: 'permanent_home',
    label: 'questions.permanent_home.label',
    title: 'questions.permanent_home.title',
    description: 'questions.permanent_home.description',
    type: 'multiple_choice',
    answerLimit: 4,
    required: true,
    answerType: {
      type: 'Pill',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    lightbox: {
      title: 'More examples of what a Permanent Home is',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    answers: [
      {
        id: 'permanent_home_answer_1',
        label: 'questions.permanent_home.answers.0.label',
        key: 'hasHistoricalTies',
        value: 'none',
      },
    ],
    component: 'default/PermanentHome',
    key: 'hasPh',
    toCountry: true,
  },
  stay: {
    id: 'stay',
    label: 'questions.stay.label',
    title: 'questions.stay.title_regular',
    irregularTitle: 'questions.stay.title_irregular',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    lightbox: {
      title: 'How to count the days I stayed somewhere?',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    answers: [
      {
        id: 'question_6_answer_1',
        label: 'questions.stay.answers.0.label',
        key: 'monthsType',
        value: 'none',
      },
      {
        id: 'question_6_answer_2',
        label: 'questions.stay.answers.1.label',
        aditionalQuestions: ['months'],
        key: 'monthsType',
        value: 'less_183_days',
      },
      {
        id: 'question_6_answer_3',
        label: 'questions.stay.answers.2.label',
        key: 'monthsType',
        value: 'more_six_months',
      },
    ],
    component: 'Question',
  },
  months: {
    id: 'months',
    label: 'questions.stay.aditionalQuestions.0.label',
    title: 'questions.stay.aditionalQuestions.0.title',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [
      {
        id: 'question_4_1_answer_1',
        label: 'questions.stay.aditionalQuestions.0.answers.0.label',
        key: 'monthsType',
        value: 'less_two_months',
      },
      {
        id: 'question_4_1_answer_2',
        label: 'questions.stay.aditionalQuestions.0.answers.1.label',
        key: 'monthsType',
        value: 'two_months',
      },
      {
        id: 'question_4_1_answer_3',
        label: 'questions.stay.aditionalQuestions.0.answers.2.label',
        key: 'monthsType',
        value: 'three_months',
      },
      {
        id: 'question_4_1_answer_4',
        label: 'questions.stay.aditionalQuestions.0.answers.3.label',
        key: 'monthsType',
        value: 'four_months',
      },
      {
        id: 'question_4_1_answer_5',
        label: 'questions.stay.aditionalQuestions.0.answers.4.label',
        key: 'monthsType',
        value: 'five_months',
      },
      {
        id: 'question_4_1_answer_6',
        label: 'questions.stay.aditionalQuestions.0.answers.5.label',
        key: 'monthsType',
        value: 'six_months',
        aditionalQuestions: ['days'],
      },
    ],
    component: 'Question',
  },
  days: {
    id: 'days',
    label: 'questions.stay.aditionalQuestions.1.label',
    title: 'questions.stay.aditionalQuestions.1.title',
    description: 'questions.stay.aditionalQuestions.1.description',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [
      {
        id: 'question_4_1_answer_1',
        label: 'questions.stay.aditionalQuestions.1.answers.0.label',
        key: 'monthsType',
        value: 'less_183_days',
      },
      {
        id: 'question_4_1_answer_2',
        label: 'questions.stay.aditionalQuestions.1.answers.1.label',
        key: 'monthsType',
        value: '183_days',
      },
      {
        id: 'question_4_1_answer_3',
        label: 'questions.stay.aditionalQuestions.1.answers.2.label',
        key: 'monthsType',
        value: 'more_six_months',
      },
      {
        id: 'question_4_1_answer_4',
        label: 'questions.stay.aditionalQuestions.1.answers.3.label',
        key: 'monthsType',
        value: 'six_months', // The "I don't know exactly" answer should result in using six months (184 days)
      },
    ],
    component: 'default/HowManyDays',
  },
  recap: {
    id: 'recap',
    label: 'questions.recap.label',
    title: 'questions.recap.title',
    type: '',
    answerLimit: 0,
    required: false,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [],
    component: 'default/Summary/Summary',
    lightbox: {
      title: 'questions.recap.lightbox.title',
      text: [],
    },
    hook: 'default/Summary/useSummary',
  },
  employment: {
    id: 'employment',
    label: 'questions.employment.label',
    title: 'questions.employment.title',
    description: 'questions.employment.description',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [
      {
        id: 'question_7_answer_1',
        label: 'questions.employment.answers.0.label',
        key: 'hasWorked',
        value: true,
      },
      {
        id: 'question_7_answer_2',
        label: 'questions.employment.answers.1.label',
        key: 'hasWorked',
        value: false,
      },
    ],
    lightbox: {
      title: 'What if I work remotely / as a contractor?',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    component: 'Question',
  },
  ties: {
    id: 'ties',
    label: 'questions.ties.label',
    title: 'questions.ties.title',
    description: 'questions.ties.description',
    type: 'multiple_choice',
    answerLimit: 1000,
    required: false,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithIcon,
    },
    answers: [
      {
        id: 'question_8_answer_1',
        label: 'questions.ties.answers.0.label',
        description: 'questions.ties.answers.0.description',
        icon: 'House',
        key: 'ties.hasPartnerOrChild',
        value: 'hasPartnerOrChild',
      },
      {
        id: 'question_8_answer_2',
        label: 'questions.ties.answers.1.label',
        description: 'questions.ties.answers.1.description',
        icon: 'GroupUser',
        key: 'ties.hasDirectFamily',
        value: 'hasDirectFamily',
      },
      {
        id: 'question_8_answer_3',
        label: 'questions.ties.answers.2.label',
        description: 'questions.ties.answers.2.description',
        icon: 'Membership',
        key: 'ties.hasMembership',
        value: 'hasMembership',
      },
      {
        id: 'question_8_answer_4',
        label: 'questions.ties.answers.3.label',
        description: 'questions.ties.answers.3.description',
        icon: 'Subscription',
        key: 'ties.hasSubscription',
        value: 'hasSubscription',
      },
      {
        id: 'question_8_answer_5',
        label: 'questions.ties.answers.4.label',
        description: 'questions.ties.answers.4.description',
        icon: 'BankBuilding',
        key: 'ties.hasBankAccount',
        value: 'hasBankAccount',
      },
      {
        id: 'question_8_answer_6',
        label: 'questions.ties.answers.5.label',
        description: 'questions.ties.answers.5.description',
        icon: 'Car',
        key: 'ties.hasCar',
        value: 'hasCar',
      },
      {
        id: 'question_8_answer_7',
        label: 'questions.ties.answers.6.label',
        description: 'questions.ties.answers.6.description',
        icon: 'Doctor',
        key: 'ties.hasFamilyDoctor',
        value: 'hasFamilyDoctor',
      },
      {
        id: 'question_8_answer_8',
        label: 'questions.ties.answers.7.label',
        description: 'questions.ties.answers.7.description',
        icon: 'Insurance',
        key: 'ties.hasInsurance',
        value: 'hasInsurance',
      },
    ],
    lightbox: {
      title: 'Why does this matter?',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    component: 'Question',
  },
  residency: {
    id: 'residency',
    label: 'questions.residency.label',
    title: 'questions.residency.title_regular',
    irregularTitle: 'questions.residency.title_irregular',
    type: 'multiple_choice',
    answerLimit: 2,
    required: true,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [
      {
        id: 'question_9_answer_1',
        label: 'questions.residency.answers.0.label',
        key: 'hasRegisteredResident',
        value: true,
      },
      {
        id: 'question_9_answer_2',
        label: 'questions.residency.answers.1.label',
        key: 'hasRegisteredResident',
        value: false,
      },
    ],
    lightbox: {
      title: 'What are examples of being registered as a resident somewhere?',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    component: 'Question',
  },
  permanent_home_country: {
    id: 'permanent_home_country',
    label: 'questions.permanent_home_country.label',
    description: 'questions.permanent_home_country.description',
    title: 'questions.permanent_home_country.title',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [
      {
        id: 'permanent_home_country_answer_1',
        label: 'questions.permanent_home_country.answers.0.label',
        key: 'hasPh',
        value: true,
      },
      {
        id: 'permanent_home_country_answer_2',
        label: 'questions.permanent_home_country.answers.1.label',
        key: 'hasPh',
        value: false,
      },
    ],
    lightbox: {
      title: 'More examples of what a Permanent Home is',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    component: 'default/PermanentHomeByCountry',
  },
  visa: {
    id: 'visa',
    label: 'questions.visa.label',
    title: 'questions.visa.title',
    description: 'questions.visa.description',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [
      {
        id: 'question_10_answer_1',
        label: 'questions.visa.answers.0.label',
        key: 'visaType',
        value: 'permanent',
      },
      {
        id: 'question_10_answer_2',
        label: 'questions.visa.answers.1.label',
        key: 'visaType',
        value: 'tourist_temporary',
      },
      {
        id: 'question_10_answer_3',
        label: 'questions.visa.answers.2.label',
        key: 'visaType',
        value: 'none',
      },
    ],
    lightbox: {
      title: 'Why does this matter?',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
    component: 'default/Visa',
  },
  personal_ties: {
    id: 'personal_ties',
    label: 'questions.personal_ties.label',
    title: 'questions.personal_ties.title',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Pill',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    answers: [
      {
        id: 'personal_ties_answer_1',
        label: 'questions.personal_ties.answers.0.label',
        key: 'hasCovi',
        value: 'none',
      },
    ],
    component: 'default/PersonalTies',
    toCountry: true,
    key: 'hasCovi',
    lightbox: {
      title: 'Why does this matter?',
      text: [
        {
          title: 'Lorem ipsum dolor sit amet',
          text: 'Consectetur adipiscing elit. Morbi elementum id odio sit amet varius. Praesent eget lectus in quam bibendum dignissim sed in felis.',
        },
        {
          title: 'Suspendisse sed molestie dolor',
          text: 'Nunc sodales tempor vestibulum. Nullam hendrerit, metus non sollicitudin fermentum, odio enim consequat tortor, ac pellentesque eros lorem vel ante.',
        },
      ],
    },
  },
  personal_description: {
    id: 'personal_description',
    label: 'questions.personal_description.label',
    title: 'questions.personal_description.title',
    description: 'questions.personal_description.description',
    type: 'multiple_choice',
    answerLimit: 2,
    required: true,
    answerType: {
      type: 'Radio',
      variation: ANSWER_VARIATION.WithIcon,
    },
    answers: [
      {
        id: 'question_12_answer_1',
        label: 'questions.personal_description.answers.0.label',
        key: 'selfDescription.answers',
        icon: 'Browser',
        value: 'digital_nomad',
      },
      {
        id: 'question_12_answer_2',
        label: 'questions.personal_description.answers.1.label',
        key: 'selfDescription.answers',
        value: 'entrepreneur',
        icon: 'Entrepreneur',
      },
      {
        id: 'question_12_answer_3',
        label: 'questions.personal_description.answers.2.label',
        key: 'selfDescription.answers',
        icon: 'GlobePin',
        value: 'expat',
      },
      {
        id: 'question_12_answer_4',
        label: 'questions.personal_description.answers.3.label',
        key: 'selfDescription.answers',
        value: 'startup_employee',
        icon: 'Employee',
      },
      {
        id: 'question_12_answer_5',
        label: 'questions.personal_description.answers.4.label',
        key: 'selfDescription.answers',
        icon: 'Contract',
        value: 'tax_optimizer',
      },
      {
        id: 'question_12_answer_6',
        label: 'questions.personal_description.answers.5.label',
        key: 'selfDescription.answers',
        icon: 'Crypto',
        value: 'crypto_investor',
      },
      {
        id: 'question_12_answer_8',
        label: 'questions.personal_description.answers.6.label',
        key: 'selfDescription.answers',
        value: 'other',
        icon: 'Other',
        aditionalQuestions: ['detailed_description'],
      },
    ],
    component: 'Question',
    isLastQuestion: true,
    toMetadata: true,
    key: 'selfDescription.answers',
  },
  detailed_description: {
    id: 'detailed_description',
    label: 'questions.detailed_description.label',
    title: 'questions.detailed_description.title',
    description: 'questions.detailed_description.description',
    type: 'multiple_choice',
    answerLimit: 1,
    required: true,
    answerType: {
      type: 'Input',
      variation: ANSWER_VARIATION.WithLabelBorder,
    },
    key: 'selfDescription.other',
    answers: [
      {
        id: 'question_12_1_answer_1',
        label: 'questions.detailed_description.answers.0.label',
        key: 'selfDescription.other',
        value: '',
      },
    ],
    component: 'default/DetailedDescription',
    isLastQuestion: true,
    toMetadata: true,
  },
};

export default defaultQuestions;
